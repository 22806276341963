<template>
	<div>
		<v-card-text v-if="isDrawing2dImageActive" class="py-0">
			<v-btn size="36" variant="text" icon @click="toggleExpansion" class="ml-0">
				<v-icon> mdi-play {{ expansion ? "mdi-rotate-90" : "" }} </v-icon>
			</v-btn>
			<span>{{ $t("DRAWING_2D_IMAGE") }}</span>
		</v-card-text>
		<v-expand-transition>
			<div v-show="expansion" class="list mx-3 mb-3" id="drawing2dImageList">
				<v-card-text v-if="drawing2dImageList.length == 0" class="pa-0 pl-10">
					{{ $t("NO_DATA") }}
				</v-card-text>
				<draggable animation="553" handle=".handle" v-model="drawing2dImageListDraggable"
					:item-key="(item) => item.getId()">
					<template #item="{ element }">
						<v-card-text class="pa-0 handle">
							<div class="list">
								<drawing-2d-image-list-item :entity="element" :draggable="true"></drawing-2d-image-list-item>
							</div>
						</v-card-text>
					</template>
				</draggable>
			</div>
		</v-expand-transition>
	</div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import Drawing2dImageListItem from "./Drawing2dImageListItem.vue";

export default {
	components: {
		draggable,
		Drawing2dImageListItem,
	},
	name: "Drawing2dImageList",
	data() {
		return {
			expansion: false,
			isDrawing2dImageActive: window.featureFlag.drawing2dImage,
			drawing2dImageListDraggable: [], // 初期値として空の配列を設定
		};
	},
	computed: {
		...mapState("drawing2dImage", ["drawing2dImageList"]),
	},
	watch: {
		async drawing2dImageList(newList) {
			await this.updateDrawing2dImageListDraggable(newList);
		},
	},
	methods: {
		toggleExpansion() {
			this.expansion = !this.expansion;
		},
		async updateDrawing2dImageListDraggable(newList) {
			// await cesiumCommon.addDrawing2dImage(newList);
			this.drawing2dImageListDraggable = newList;
		},
	},
	async created() {
		await this.updateDrawing2dImageListDraggable(this.drawing2dImageList);
	},
};
</script>

<style lang="scss" scoped>
.list {
	max-height: 150px;
	overflow-y: auto;
	overflow-x: hidden;
	scroll-behavior: smooth;
}
</style>