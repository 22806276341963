import { Drawing2dImage } from "../../models/Drawing2DImage";
import axios from "axios";
import localDb from "../../utils/local-db";

const state = {
	drawing2dImageList: [],
};

const getters = {
	getDrawing2dImageList: (state) => state.drawing2dImageList,
};

const mutations = {
	addDrawing2dImage: (state, drawing2dImage) => {
		state.drawing2dImageList = [...state.drawing2dImageList, drawing2dImage];
	},
	updateDrawing2dImageList: (state, drawing2dImageList) => {
		//syncronize
		for (const obj of state.drawing2dImageList) {
			const index = drawing2dImageList.findIndex(
				(o) => o.assetId === obj.assetId,
			);
			if (index !== -1) {
				drawing2dImageList.splice(index, 1);
			}
		}
		state.drawing2dImageList = [
			...state.drawing2dImageList,
			...drawing2dImageList,
		];
	},
	deleteDrawing2dImageListStateById: (state, assetId) => {
		const drawing2dImageList = state.drawing2dImageList.filter(
			(drawing2dImage) => drawing2dImage.assetId !== assetId,
		);
		state.drawing2dImageList = drawing2dImageList;
	},
};

const actions = {
	async addDrawing2dImage({ state, commit }, { siteId, drawing2dImage }) {
		commit("addDrawing2dImage", drawing2dImage);
	},
	async getDrawing2dImageList({ state, commit }, site_id) {
		// call backend api to get all drawing 2d image list
		// get drawing 2d image list from server but currently hard coded TODO:mr-akami
		const res = await axios.get(
			`${import.meta.env.VITE_API_BASE}/drawing2dImage/${site_id}`,
			{
				auth: this.state.authObject,
			},
		);
		const drawing2dImageList = [];
		for (const obj of res.data) {
			const drawing2dImage = new Drawing2dImage({
				file: null,
				name: obj.drawing_name,
				fileName: obj.image_name,
				assetId: obj.id,
				isAssetUploaded: true,
				transparency: 0.5,
				isVisible: false,
				selectedDate: new Date(obj.capture_at).toLocaleDateString(),
				selectedTime: new Date(obj.capture_at).toLocaleTimeString(),
			});
			drawing2dImageList.push(drawing2dImage);
		}
		commit("updateDrawing2dImageList", drawing2dImageList);
	},
	async deleteDrawing2dImage({ commit }, { siteId, drawing2dImage }) {
		try {
			await axios.delete(
				`${import.meta.env.VITE_API_BASE}/drawing2dImage/${siteId}/${drawing2dImage.assetId}`,
				{
					auth: state.authObject,
				},
			);
			localDb.deleteDrawing2DImage(
				state.user.id,
				state.site.site_uuid,
				asset_id,
			);
		} catch (e) {
			console.warn(e);
			throw e;
		}
		commit("deleteDrawing2dImageListStateById", drawing2dImage.assetId);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
