<template>
	<span>
		<v-card class="justify-center info-dialog">
			<v-card-title class="d-flex align-center justify-space-between">
				{{ $t("CQW0020900002") }}
				<v-btn v-if="isTopPage" icon variant="flat" size="small" @click="closeDialog()">
					<v-icon> mdi-close </v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="note">
				<div>
					{{ $t("SELECT_SITE_COORDINATE_SYSTEM_NOTE") }}
				</div>
				<div class="alert-text-area">
					<v-icon> mdi-alert </v-icon>
					<div class="alert-text">
						{{ $t("SELECT_SITE_COORDINATE_SYSTEM_ALERT") }}
					</div>
				</div>
			</v-card-text>
			<v-card-text class="mx-auto fill-height fluid content">
				<v-table density="compact">
					<tbody>
						<tr>
							<td></td>
							<td class="pa-0">
								<v-radio-group v-model="selectedCoordinateSystem" hide-details color="primary">
									<v-radio value="otherApp" :label="$t('COORDINATE_SYSTEM_FROM', { name: $t('OTHER_APPLICATION') })">
									</v-radio>
								</v-radio-group>
							</td>
							<td class="pa-0">
								<v-radio-group v-model="selectedCoordinateSystem" hide-details color="primary">
									<v-radio value="design3d" :label="$t('COORDINATE_SYSTEM_FROM', { name: $t('DESIGN_3D') })">
									</v-radio>
								</v-radio-group>
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_ROTATION") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.rotation_angle }}
							</td>
							<td>
								{{ coordinateSystemDb?.rotation }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_ORIGIN_EASTING") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.false_easting }}
							</td>
							<td>
								{{ coordinateSystemDb?.origin_easting }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_ORIGIN_NORTHING") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.false_northing }}
							</td>
							<td>
								{{ coordinateSystemDb?.origin_northing }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_ORIGIN_LATITUDE") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.latitude_of_origin }}
							</td>
							<td>
								{{ coordinateSystemDb?.origin_latitude }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_ORIGIN_LONGITUDE") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.central_meridian }}
							</td>
							<td>
								{{ coordinateSystemDb?.origin_longitude }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_SCALE_FACTOR") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.scale_factor }}
							</td>
							<td>
								{{ coordinateSystemDb?.scale_factor }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_VERTICAL_OFFSET") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.vertical_offset }}
							</td>
							<td>
								{{ coordinateSystemDb?.vertical_offset }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_INCLINE_X") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.inclination_x }}
							</td>
							<td>
								{{ coordinateSystemDb?.incline_x }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_INCLINE_Y") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.inclination_y }}
							</td>
							<td>
								{{ coordinateSystemDb?.incline_y }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_TO_METERS") }}
							</td>
							<td>
								{{ coordinateSystemPf?.localization?.projection.parameters?.unit?.ratio }}
							</td>
							<td>
								{{ coordinateSystemDb?.to_meters }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_COORDINATE_SYSTEM") }}
							</td>
							<td>
								{{ coordinateSystemPf?.projection?.srid }}
							</td>
							<td>
								{{ coordinateSystemDb?.epsg }}
							</td>
						</tr>
						<tr>
							<td>
								{{ $t("LABEL_VERTICAL_SHIFT") }}
							</td>
							<td>
								{{ coordinateSystemPf?.projection?.vertical_srid }}
							</td>
							<td>
								{{ coordinateSystemDb?.epsg_v }}
							</td>
						</tr>
					</tbody>
				</v-table>
			</v-card-text>
			<v-card-actions class="justify-end submit-button">
				<v-btn variant="flat" width="140px" min-height="31px" @click="submit()" color="primary" rounded
					:disabled="isDisableSubmitButton">
					{{ isTopPage ? $t("OPEN_THE_SITE") : $t("OK") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</span>
</template>

<script>
export default {
	name: "SelectSiteCoordinateSystem",
	props: {
		siteId: {
			type: String,
			default: "",
		},
		coordinateSystemPf: {
			type: Object,
			default: null,
		},
		coordinateSystemDb: {
			type: Object,
			default: null,
		},
		isTopPage: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			selectedCoordinateSystem: "",
		};
	},
	computed: {
		isDisableSubmitButton() {
			return this.selectedCoordinateSystem === "";
		},
	},
	methods: {
		closeDialog() {
			this.$emit("close");
			return;
		},
		submit() {
			if (this.selectedCoordinateSystem === "design3d") {
				// Not update DB
				this.$emit("update", this.siteId, null);
			} else {
				// Update DB if use coordinate system of PF
				const params = {
					epsg: "",
					epsg_v: "",
					rotation: "",
					origin_easting: "",
					origin_northing: "",
					origin_latitude: "",
					origin_longitude: "",
					scale_factor: "",
					vertical_offset: "",
					incline_x: "",
					incline_y: "",
					to_meters: "",
				};

				params.epsg = this.coordinateSystemPf?.projection?.srid;
				params.epsg_v = this.coordinateSystemPf?.projection?.vertical_srid;
				params.rotation =
					this.coordinateSystemPf?.localization?.projection.parameters?.rotation_angle;
				params.origin_easting =
					this.coordinateSystemPf?.localization?.projection.parameters?.false_easting;
				params.origin_northing =
					this.coordinateSystemPf?.localization?.projection.parameters?.false_northing;
				params.origin_latitude =
					this.coordinateSystemPf?.localization?.projection.parameters?.latitude_of_origin;
				params.origin_longitude =
					this.coordinateSystemPf?.localization?.projection.parameters?.central_meridian;
				params.scale_factor =
					this.coordinateSystemPf?.localization?.projection.parameters?.scale_factor;
				params.vertical_offset =
					this.coordinateSystemPf?.localization?.projection.parameters?.vertical_offset;
				params.incline_x =
					this.coordinateSystemPf?.localization?.projection.parameters?.inclination_x;
				params.incline_y =
					this.coordinateSystemPf?.localization?.projection.parameters?.inclination_y;
				params.to_meters =
					this.coordinateSystemPf?.localization?.projection.parameters?.unit?.ratio;

				this.$emit("update", this.siteId, params);
			}
			return;
		},
	},
};
</script>

<style scoped>
.info-dialog {
	width: 100%;
}

.note {
	padding-bottom: 0;
}

.alert-text-area {
	padding: 8px;
	font-weight: bold;
	display: flex;
	flex-direction: row;

	.alert-text {
		padding-left: 8px;
	}
}

.content {
	width: 90%;
	height: 100%;
	padding-top: 0;
}

.submit-button {
	padding-bottom: 5%;
	padding-right: 5%;
}
</style>
