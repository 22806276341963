<template>
	<div>
		<tool-panel ref="tool_panel" />
		<chart-board ref="chartboard" />
		<v-dialog v-model="showExportDialog" width="32%" max-width="500px" persistent>
			<export-landXML-dialog :name="name" :geometry="geometry" @close="closePropertyDialog()" ref="export_dialog" />
		</v-dialog>
		<v-dialog v-model="showPropertyDialogRoad" width="72%" max-width="1072px" persistent>
			<property-dialog-road v-if="showPropertyDialogRoad" :id="id" :type="type" @close="closePropertyDialog()"
				ref="property_dialog_road" />
		</v-dialog>
		<v-dialog v-model="showPropertyDialogTrench" width="72%" max-width="1072px" persistent>
			<property-dialog-trench v-if="showPropertyDialogTrench" :id="id" :type="type" @close="closePropertyDialog()"
				ref="property_dialog_trench" />
		</v-dialog>
		<v-dialog v-model="showPropertyDialogFlat" width="72%" max-width="1072px" persistent>
			<property-dialog-flat v-if="showPropertyDialogFlat" :id="id" :type="type" @close="closePropertyDialog()"
				ref="property_dialog_flat" />
		</v-dialog>
		<v-dialog v-model="showUpdateCtrlPointDialog" width="32%" max-width="1072px" persistent>
			<update-ctrl-point-dialog :id="id" :pointId="pointId" :entityUnderEdit="entityUnderEdit"
				:disabilityConditions="disabilityConditions" :origin="origin" @close="closePropertyDialog()"
				ref="update_ctrl_point_dialog" />
		</v-dialog>
		<v-dialog v-model="showAddRoadTerrainByAI" width="100%" persistent>
			<AddRoadTerrainByAI :id="id" :type="type" @close="closePropertyDialog()" ref="property_dialog_road_ai" />
		</v-dialog>
		<v-dialog v-model="showAddRoadByJson" width="100%" persistent>
			<AddRoadByJson :id="id" :type="type" @close="closePropertyDialog()" ref="property_dialog_json" />
		</v-dialog>

		<v-dialog v-model="showSegmentSelection" max-width="460px" persistent :scrim="false">
			<SegmentSelectionDialog :id="id" :start-index="startIndex" :points-count="pointsCount"
				@close="closePropertyDialog()" ref="segment_selection_dialog" />
		</v-dialog>
		<v-dialog v-model="showExportFromDashboardDialog" max-width="560px" persistent>
			<ExportFromDashboardDialog :id="id" :isShow="showExportFromDashboardDialog" @close="closePropertyDialog()"
				ref="property_dialog_export_from_dashboard" />
		</v-dialog>
		<v-dialog v-model="showArbitraryLineDialog" max-width="460px" persistent>
			<add-arbitrary-line-cross-section-dialog v-if="showArbitraryLineDialog" :viewer-line-id="id"
				@close="closePropertyDialog()" ref="property_dialog_arbitrary_line" />
		</v-dialog>
		<v-dialog v-model="showLineWorksCrossSectionDialog" max-width="460px" persistent>
			<LineWorksCrossSectionDialog :id="id" @close="closePropertyDialog()" ref="line_works_cross_section_dialog">
			</LineWorksCrossSectionDialog>
		</v-dialog>
		<v-dialog v-model="showLineWorksCrossSectionEditDialog" max-width="460px" persistent>
			<line-works-cross-section-edit-dialog v-if="showLineWorksCrossSectionEditDialog"
				:cross-section-data="editCrossSectionData.crossSectionData" :line-works-id="editCrossSectionData.lineWorksId"
				@close="closePropertyDialog()"
				ref="line_works_cross_section_edit_dialog"></line-works-cross-section-edit-dialog>
		</v-dialog>
		<v-dialog v-model="showSelectObjectForLinearCreationDialog" max-width="60vw" max-height="60vh" persistent>
			<SelectObjectForLinearCreationDialog :isShow="showSelectObjectForLinearCreationDialog"
				@close="closePropertyDialog()"></SelectObjectForLinearCreationDialog>
		</v-dialog>
		<v-dialog v-model="showSelectCoordinateDialog" width="800px" v-if="showSelectCoordinateDialog" persistent>
			<select-site-coordinate-system :site-id="this.$route.query.siteId" :coordinate-system-pf="this.coordinateSystemPf"
				:coordinate-system-db="this.coordinateSystemDb" :is-top-page="false" @update="updateCoordinateSystem" />
		</v-dialog>
	</div>
</template>

<script>
import { useGlobalDialogs } from "@/composables/useGlobalDialogs.js";
import * as cesiumCommon from "@/utils/cesium-common";
import objectLockWebSocket from "@/utils/objectLockWebSocket";
import axios from "axios";
import { mapActions, mapMutations } from "vuex";
import SelectObjectForLinearCreationDialog from "../components/AddData/SelectObjectForLinearCreationDialog.vue";
import SelectSiteCoordinateSystem from "../components/AddData/SelectSiteCoordinateSystem.vue";
import AddRoadByJson from "../components/Project/AddRoadByJson";
import AddRoadTerrainByAI from "../components/Project/AddRoadTerrainByAI.vue";
import ChartBoard from "../components/Project/ChartBoard/ChartBoard.vue";
import ExportLandXMLDialog from "../components/Project/ExportLandXMLDialog.vue";
import ExportFromDashboardDialog from "../components/Project/ExportFromDashboardDialog.vue";
import AddArbitraryLineCrossSectionDialog from "../components/Project/LineWorks/AddArbitraryLineCrossSectionDialog.vue";
import LineWorksCrossSectionDialog from "../components/Project/LineWorks/LineWorksCrossSectionDialog.vue";
import LineWorksCrossSectionEditDialog from "../components/Project/LineWorks/LineWorksCrossSectionEditDialog.vue";
import { defaultLineWorksCrossSection } from "../components/Project/LineWorks/default";
import PropertyDialogFlat from "../components/Project/PropertyDialogFlat.vue";
import PropertyDialogRoad from "../components/Project/PropertyDialogRoad.vue";
import PropertyDialogTrench from "../components/Project/PropertyDialogTrench.vue";
import SegmentSelectionDialog from "../components/Project/SegmentSelectionDialog.vue";
import ToolPanel from "../components/Project/ToolPanel.vue";
import UpdateCtrlPointDialog from "../components/Project/UpdateCtrlPointDialog.vue";
import localDb from "../utils/local-db";
import {
	checkPointCloudDataExistence,
	getPreconstructionNode,
	getSiteLatitudeAndLongitude,
	importDashboardPreconstruction,
} from "../utils/sitePreparationUtils";

export default {
	setup() {
		const {
			showPropertyDialogRoad,
			showPropertyDialogTrench,
			showPropertyDialogFlat,
		} = useGlobalDialogs();
		return {
			showPropertyDialogRoad,
			showPropertyDialogTrench,
			showPropertyDialogFlat,
		};
	},
	name: "ProjectPage",
	components: {
		ToolPanel,
		ChartBoard,
		ExportLandXMLDialog,
		PropertyDialogRoad,
		PropertyDialogTrench,
		PropertyDialogFlat,
		UpdateCtrlPointDialog,
		AddRoadTerrainByAI,
		AddRoadByJson,
		SegmentSelectionDialog,
		ExportFromDashboardDialog,
		LineWorksCrossSectionDialog,
		LineWorksCrossSectionEditDialog,
		AddArbitraryLineCrossSectionDialog,
		SelectObjectForLinearCreationDialog,
		SelectSiteCoordinateSystem,
	},
	data: () => ({
		showExportDialog: false,
		showUpdateCtrlPointDialog: false,
		showAddRoadTerrainByAI: false,
		showAddRoadByJson: false,
		showSegmentSelection: false,
		showExportFromDashboardDialog: false,
		showSelectObjectForLinearCreationDialog: false,
		showSelectCoordinateDialog: false,
		id: null,
		type: null,
		pointId: null,
		entityUnderEdit: null,
		disabilityConditions: null,
		origin: null,
		startIndex: 0,
		pointsCount: 0,
		name: null,
		geometry: null,
		showArbitraryLineDialog: false,
		showLineWorksCrossSectionDialog: false,
		showLineWorksCrossSectionEditDialog: false,
		editCrossSectionData: {
			crossSectionData: defaultLineWorksCrossSection(),
			lineWorksId: null,
		},
		coordinateSystemDb: null,
		coordinateSystemPf: null,
	}),
	beforeCreate() {
		objectLockWebSocket.connect();
	},
	async mounted() {
		const conflictCoordinateSystem =
			this.$store.getters.conflictCoordinateSystem;

		// Check site coordinate system conflicting
		if (conflictCoordinateSystem) {
			this.coordinateSystemDb = conflictCoordinateSystem.design3d;
			this.coordinateSystemPf = conflictCoordinateSystem.otherApp;

			// Show SelectSiteCoordinateSystem dialog
			this.showSelectCoordinateDialog = true;
		} else {
			// Initialization
			await this.initialization();
		}
	},
	unmounted() {
		window["viewer"].clear();
		this.set_point_cloud_list([]);
		this.set_design_list([]);
		window.removeEventListener("openPropertyDialog");
	},
	methods: {
		...mapMutations([
			"set_point_cloud_list",
			"set_design_list",
			"initObj",
			"set_snackbar",
		]),
		...mapActions(["get_point_cloud_list", "get_design_list", "get_obj_list"]),
		...mapActions("drawing2dImage", ["getDrawing2dImageList"]),
		async initialization() {
			window.addEventListener("popstate", () => {
				window.location.href = window.location.origin;
			});
			window.addEventListener("openPropertyDialog", this.openPropertyDialog);
			window.addEventListener(
				"openLineWorksEditDialog",
				this.openLineWorksEditDialog,
			);
			await this.$nextTick();
			await this.getPointCloudList();
			await this.getDesignList();
			await this._getDrawing2DImageList();
			this.$store.dispatch(
				"measurement/getMeasurementList",
				this.$route.query.siteId,
			);
			this.$store.dispatch(
				"arbitraryLine/getArbitraryLineList",
				this.$route.query.siteId,
			);
			this.$store.dispatch(
				"simaLine/getSimaLineList",
				this.$route.query.siteId,
			);
			this.loadObj(this.$route.query.siteId);
		},
		async updateCoordinateSystem(siteId, updateInfo) {
			// Hide SelectSiteCoordinateSystem dialog
			this.showSelectCoordinateDialog = false;

			this.$store.dispatch("updateIsLoading", true);
			try {
				// If selected platform values, update design3d database
				if (updateInfo) {
					await axios.post(
						`${import.meta.env.VITE_API_BASE}/sites/epsg/${siteId}`,
						updateInfo,
					);
				} else {
					// Regsiter platform if using desing3d data
					try {
						await axios.post(
							`${import.meta.env.VITE_API_BASE}/sites/${siteId}/localizations/export`,
							{
								isDashboard: false,
							},
						);
					} catch (e) {
						// Show snackbar message if localization file is not found
						if (e.response.data?.existLocalizationFile === false) {
							this.$store.commit("set_snackbar", {
								text: this.$t("MESSAGE_NOT_FOUND_LOCALIZATION_FILE"),
								color: "rgba(245, 124, 0, 0.72)",
							});
						} else {
							this.$store.commit("set_snackbar", {
								text: this.$t("MESSAGE_ERROR_FAILED_SET_COORDINATE_SYSTEM"),
								color: "rgba(153, 0, 0, 0.72)",
							});
						}
					}
				}

				// Get preconstruction data from dashboard if point cloud data does not exist
				if (!(await checkPointCloudDataExistence(siteId))) {
					try {
						const bucketInfo = await getPreconstructionNode(siteId);
						if (bucketInfo) {
							this.$store.commit("set_snackbar", {
								text: this.$t("ACQUIRING_POINT_CLOUD_DATA_FROM_OTHER_APP"),
								color: "rgba(0, 153, 0, 0.72)",
							});

							await importDashboardPreconstruction(siteId, bucketInfo);
						} else {
							throw new Error("Not found preconstruction data.");
						}
					} catch (e) {
						// no operation
					}
				}

				// Focus the site
				const { latitude, longitude } =
					await getSiteLatitudeAndLongitude(siteId);
				const pinId = window.viewer.addPin(
					Number(longitude),
					Number(latitude),
					"#00000000", // Use transparent color to be not display
				);
				window.viewer.focusPin(pinId);

				// Get latest site information and save in store
				await this.$store.dispatch("getSiteInfo", siteId);

				// Remove pin
				window.viewer.removePin(pinId);
			} catch (e) {
				this.$store.commit("set_snackbar", {
					text: this.$t("MESSAGE_ERROR_FAILED_SET_COORDINATE_SYSTEM"),
					color: "rgba(153, 0, 0, 0.72)",
				});
			} finally {
				this.$store.commit("set_conflict_coordinate_system", null);
				this.$store.dispatch("updateIsLoading", false);
			}

			// Initialization
			await this.initialization();
		},
		async getPointCloudList() {
			try {
				await this.get_point_cloud_list(this.$route.query.siteId);
				//get from localdb and load it
				const storedList = await localDb.getPointClouds(
					this.$store.state.user.id,
					this.$store.state.site.site_uuid,
				);
				const commonList = this.$store.state.point_cloud_list.filter((item) =>
					storedList.includes(item.getAssetId()),
				);
				for (const pointCloud of commonList) {
					if ((await pointCloud.waitAssetUploading()) === "DONE") {
						cesiumCommon.togglePointCloudVisibility(
							pointCloud,
							this.$store.getters.getRoads,
						);
					}
				}
			} catch (error) {
				console.log("GET POINT CLOUD LIST", error);
			}
		},
		async getDesignList() {
			try {
				await this.get_design_list(this.$route.query.siteId);
				//get from localdb and load it
				const storedList = await localDb.getDesigns(
					this.$store.state.user.id,
					this.$store.state.site.site_uuid,
				);
				const commonList = this.$store.state.design_list.filter((item) =>
					storedList.includes(item.getAssetId()),
				);
				for (const design of commonList) {
					if (design.getIsAssetUploaded()) {
						cesiumCommon.toggleDesignVisibility(design);
					}
				}
			} catch (error) {
				console.log("GET DESIGN LIST", error);
			}
		},
		async _getDrawing2DImageList() {
			try {
				await this.getDrawing2dImageList(this.$route.query.siteId);
				//get from localdb and load it
				const storedList = await localDb.getDrawing2DImages(
					this.$store.state.user.id,
					this.$store.state.site.site_uuid,
				);
				const commonList = this.$store.getters[
					"drawing2dImage/getDrawing2dImageList"
				].filter((item) => storedList.includes(item.getAssetId()));
				for (const drawing2dOmage of commonList) {
					cesiumCommon.toggleDrawing2dImageVisibility(drawing2dOmage);
				}
			} catch (error) {
				console.log("GET POINT CLOUD LIST", error);
			}
		},
		loadObj(siteId) {
			this.initObj();
			this.get_obj_list(siteId);
		},
		openLineWorksEditDialog(e) {
			console.log("received entity data", e.detail);
			this.editCrossSectionData = {
				crossSectionData: e.detail.crossSectionSettings,
				lineWorksId: e.detail.lineWorksId,
			};

			this.showLineWorksCrossSectionEditDialog = true;
		},
		openPropertyDialog(e) {
			this.id = e.detail.id;
			this.type = e.detail.type;
			this.pointId = e.detail.pointId;
			this.entityUnderEdit = e.detail.entityUnderEdit;
			this.disabilityConditions = e.detail.disabilityConditions;
			this.origin = e.detail.origin;
			switch (this.type) {
				case 0:
					this.showPropertyDialogRoad = true;
					this.$refs.property_dialog_road &&
						this.$refs.property_dialog_road.resetProperty();
					break;
				case 1:
					this.showPropertyDialogFlat = true;
					this.$refs.property_dialog_flat &&
						this.$refs.property_dialog_flat.resetProperty();
					break;
				case 2:
					// 仮設道路AI 地形データ選択ダイアログ(W002-09)
					this.showAddRoadTerrainByAI = true;
					this.$refs.property_dialog_road_ai &&
						this.$refs.property_dialog_road_ai.resetProperty();

					this.$refs.property_dialog_road_ai &&
						this.$refs.property_dialog_road_ai.fetchDesignItems();

					this.$refs.property_dialog_road_ai &&
						this.$refs.property_dialog_road_ai.fetchPointCloudItems();
					break;
				case 3:
					this.showUpdateCtrlPointDialog = true;
					this.$refs.update_ctrl_point_dialog &&
						this.$refs.update_ctrl_point_dialog.initCoordinates();
					break;
				case 4:
					// 仮設道路AI設定ダイアログ(W002-10)
					console.log("AddRoadByJson dialog");
					this.showAddRoadByJson = true;
					// 画面初期表示
					this.$refs.property_dialog_json &&
						this.$refs.property_dialog_json.setInitialize();
					break;
				case 5:
					// 仮設道路AI設定ダイアログ(W002-10)
					console.log("Show segment Selection dialog");
					//need start index
					//max possibleindex => get from store
					//road id
					//
					console.log("params", e.detail);
					this.startIndex = e.detail.clickedPointIndex;
					this.pointsCount = e.detail.totalPoints;
					this.showSegmentSelection = true;
					break;
				case 6:
					this.name = e.detail.name;
					this.geometry = e.detail.geometry;
					this.showExportDialog = true;
					this.$refs.export_dialog && this.$refs.export_dialog.resetProperty();
					break;
				case 7:
					this.showPropertyDialogTrench = true;
					this.$refs.property_dialog_trench &&
						this.$refs.property_dialog_trench.resetProperty();
					break;
				case 8:
					this.showExportFromDashboardDialog = true;
					this.$refs.property_dialog_export_from_dashboard &&
						this.$refs.property_dialog_export_from_dashboard.resetProperty();
					break;
				case 9:
					this.showArbitraryLineDialog = true;
					this.$refs.property_dialog_arbitrary_line &&
						this.$refs.property_dialog_arbitrary_line.resetProperty();
					break;
				case 10:
					this.showLineWorksCrossSectionDialog = true;
					this.$refs.line_works_cross_section_dialog &&
						this.$refs.line_works_cross_section_dialog.resetProperty();
					break;
				case 11:
					this.showSelectObjectForLinearCreationDialog = true;
					break;
				default:
					break;
			}
		},
		closePropertyDialog() {
			this.$store.dispatch("endCreationMode");
			this.showExportDialog = false;
			this.showPropertyDialogRoad = false;
			this.showPropertyDialogTrench = false;
			this.showPropertyDialogFlat = false;
			this.showArbitraryLineDialog = false;
			this.showUpdateCtrlPointDialog = false;
			this.showAddRoadTerrainByAI = false;
			this.showAddRoadByJson = false;
			this.showSegmentSelection = false;
			this.showExportFromDashboardDialog = false;
			this.showSelectObjectForLinearCreationDialog = false;
			this.$refs.tool_panel.isRoadAdd = false;
			this.$refs.tool_panel.isTrenchAdd = false;
			this.$refs.tool_panel.isFlatAdd = false;
			this.$refs.tool_panel.isAIRoadAdd = false;
			if (this.$refs.property_dialog_road) {
				this.$refs.property_dialog_road.isRoadAdded = false;
			}
			if (this.$refs.property_dialog_trench) {
				this.$refs.property_dialog_trench.isTrenchAdded = false;
			}
			if (this.$refs.property_dialog_flat) {
				this.$refs.property_dialog_flat.isFlatGroundAdded = false;
			}

			this.showLineWorksCrossSectionDialog = false;
			this.showLineWorksCrossSectionEditDialog = false;
			this.entityLineId = null;
		},
	},
};
</script>

<style scoped></style>
