import * as constants from "@/constant.js";
import Design from "@/models/Design";
import Line from "@/models/Line";
import PointCloud from "@/models/PointCloud";
import axios from "axios";
import Vuex from "vuex";
import { LongitudinalSlopeType, RubbingDestination } from "../constant";
import localDb from "../utils/local-db";
import addRoadTerrainByAi from "./modules/add-road-terrain-by-ai";
import arbitraryLine from "./modules/arbitrary-line";
import dialogs from "./modules/dialogs";
import drawing2dImage from "./modules/drawing-2d-image";
import dxf from "./modules/dxf";
import lineWorksCrossSection from "./modules/lineworks-cross-section";
import lock from "./modules/lock";
import measurement from "./modules/measurement";
import * as objectFunction from "./modules/object-function";
import simaLine from "./modules/sima-line";

const store = Vuex.createStore({
	state: {
		panelName: "",
		authObject: {
			username: "2022scdesign",
			password: "passscdesign",
		},
		site: null,
		point_cloud_list: [],
		design_list: [],
		activeData: [], // アクティブ状態の点群と設計を1つずつ保持
		axis: "",
		user: null,
		lang: "ja",
		roadParameterSlopeUnit: {
			// 勾配単位（道路・摺り付け）
			gradientArea: "percent", // 勾配範囲
			crossSlope: "percent", // 横断勾配
			superElevation: "percent", // 片勾配
			longitudinalSlope: "percent",
		},
		trenchParameterSlopeUnit: {
			gradientArea: "percent",
			crossSlope: "percent",
			superElevation: "percent",
		},
		cutSettingSlopeUnit: {
			// 勾配単位（切土設定）
			slopeGradient: "ratio", // 法面勾配
			bermGradient: "percent", // 小段勾配
		},
		trenchCutSettingSlopeUnit: {
			slopeGradient: "ratio",
			bermGradient: "percent",
		},
		trenchLongitudinalSlopeUnit: "percent",
		fillSettingSlopeUnit: {
			// 勾配単位（盛土設定）
			slopeGradient: "ratio", // 法面勾配
			bermGradient: "percent", // 小段勾配
		},
		longitudinalSectionViewerSlopeUnit: {
			// 勾配単位（縦断ビュー）
			nextGradient: "percent", // 次地点までの勾配
		},
		crossSectionViewerSlopeUnit: {
			// 勾配単位（断面ビュー）
			slopeGradient: "ratio", // 法面勾配
			bermGradient: "percent", // 小段勾配
		},
		visiblePointCloudAndDesignList: [], // 表示中の点群/設計リスト
		activeLineCrossSection: null, // 最後に表示操作を行った線形断面
		chartBoardCurrentTab: {}, // チャートボードでActive状態のタブ
		draggedTab: {},
		propertyDialog: false,
		modes: {
			inCreationMode: {
				isActive: false, // 作成モードかどうか
				objectType: null, // 作成オブジェクトのタイプ
			},
			inEditMode: {
				isActive: false, // 編集モードかどうか
				editTargetId: null, // 編集オブジェクトのID
			},
			ai_road_point_selection: false,
			isFlatGroundHoriDrag: false,
			isFlatGroundVertiDrag: false,
			isFlatGroundUniformDrag: false,
			inEditDrawing2dImage: {
				isActive: false, // Whether or not in editing
				editTargetId: null, // The ID of editing image
			},
		},
		objects: [],
		isObjectUpdating: false,
		snackbars: [],
		isLoading: false,
		ai_road_preview_data: {
			x1: undefined,
			y1: undefined,
			z1: undefined,
			x2: undefined,
			y2: undefined,
			z2: undefined,
			startPointHeightAbovePointCloud: undefined,
			startPointHeightAboveDesignData: undefined,
			endPointHeightAbovePointCloud: undefined,
			endPointHeightAboveDesignData: undefined,
		},
		initial_site_data: {},
		isExclusion: false,
		viewer: undefined,
		is_crs_conversion_in_progress: false,
		conflictCoordinateSystem: null,
	},
	mutations: {
		set_viewer(state, viewer) {
			state.viewer = viewer;
		},
		change_panel(state, panelName) {
			state.panelName = panelName;
		},
		set_modes(state, name_value) {
			state.modes[name_value.name] = name_value.value;
		},
		set_ai_road_preview_data(state, obj) {
			state.ai_road_preview_data.x1 = obj.x1;
			state.ai_road_preview_data.y1 = obj.y1;
			state.ai_road_preview_data.z1 = obj.z1;
			state.ai_road_preview_data.x2 = obj.x2;
			state.ai_road_preview_data.y2 = obj.y2;
			state.ai_road_preview_data.z2 = obj.z2;
			state.ai_road_preview_data.startPointHeightAbovePointCloud =
				obj.startPointHeightAbovePointCloud;
			state.ai_road_preview_data.startPointHeightAboveDesignData =
				obj.startPointHeightAboveDesignData;
			state.ai_road_preview_data.endPointHeightAbovePointCloud =
				obj.endPointHeightAbovePointCloud;
			state.ai_road_preview_data.endPointHeightAboveDesignData =
				obj.endPointHeightAboveDesignData;
		},
		set_ai_road_preview_data_property(state, property) {
			state.ai_road_preview_data[property.name] = property.value;
		},
		reset_ai_road_preview_data(state) {
			state.ai_road_preview_data = {
				x1: undefined,
				y1: undefined,
				z1: undefined,
				x2: undefined,
				y2: undefined,
				z2: undefined,
				startPointHeightAbovePointCloud: undefined,
				startPointHeightAboveDesignData: undefined,
				endPointHeightAbovePointCloud: undefined,
				endPointHeightAboveDesignData: undefined,
			};
		},
		setSite(state, site) {
			state.site = site;
		},
		set_point_cloud_list(state, point_cloud_list) {
			state.point_cloud_list = point_cloud_list;
		},
		set_point_cloud_loading(state, point_cloud_id) {
			const index = state.point_cloud_list.findIndex(
				(item) => item.point_cloud_id == point_cloud_id,
			);
			state.point_cloud_list[index].loading = true;
		},
		delete_point_cloud(state, point_cloud_id) {
			const index = state.point_cloud_list.findIndex(
				(item) => item.point_cloud_id == point_cloud_id,
			);
			state.point_cloud_list.splice(index, 1);
		},
		set_design_list(state, design_list) {
			state.design_list = design_list;
		},
		set_design_loading(state, design_id) {
			const index = state.design_list.findIndex(
				(item) => item.design_id == design_id,
			);
			state.design_list[index].loading = true;
		},
		// 線形データ
		set_lineworks_loading(state, lineworks_id) {
			const index = state.design_list.findIndex(
				(item) => item._id === lineworks_id,
			);
			if (index !== -1) {
				state.design_list[index].loading = true;
			}
		},
		add_design(state, design) {
			switch (design.data_type) {
				case "design": {
					state.design_list.push(
						new Design(
							design.data_type,
							design.design_id,
							design.design_name,
							design.asset_id,
							design.color,
							design.transparency,
							design.processing_status,
							design.processing_error,
							design.created_at,
							design.selected_date,
							design.selected_time,
						),
					);
					break;
				}
				case "lineworks": {
					state.design_list.push(
						new Line(
							design.data_type,
							design.lineworks_id,
							design.lineworks_name,
							design.lineworks_path,
							design.processing_status,
							design.create_at,
							design.site_id,
							design.line_type,
						),
					);
					break;
				}
			}
		},
		delete_design(state, design_id) {
			const index = state.design_list.findIndex(
				(item) => item.design_id == design_id,
			);
			state.design_list.splice(index, 1);
		},
		// 線形データ
		delete_lineworks(state, lineworks_id) {
			const index = state.design_list.findIndex(
				(item) => item._id == lineworks_id,
			);
			if (index !== -1) {
				state.design_list.splice(index, 1);
			}
		},
		setUser(state, user) {
			state.user = user;
			this.commit("setLang");
		},
		setLang(state) {
			if (state.user.language === "en-US") {
				state.lang = "en";
			} else {
				state.lang = state.user.language;
			}
		},
		setChartBoardCurrentTab(state, tab) {
			state.chartBoardCurrentTab = tab;
		},
		setDraggedTab(state, tab) {
			state.draggedTab = tab;
		},
		togglePropertyDialog() {
			this.state.propertyDialog = !this.state.propertyDialog;
		},
		toggleRoadParameterSlopeUnit(state, property) {
			for (const key of Object.keys(property)) {
				state.roadParameterSlopeUnit[key] = property[key];
			}
		},
		toggleCutSettingSlopeUnit(state, property) {
			for (const key of Object.keys(property)) {
				state.cutSettingSlopeUnit[key] = property[key];
			}
		},
		toggleTrenchParameterSlopeUnit(state, property) {
			for (const key of Object.keys(property)) {
				state.trenchParameterSlopeUnit[key] = property[key];
			}
		},
		toggleTrenchCutSettingSlopeUnit(state, property) {
			for (const key of Object.keys(property)) {
				state.trenchCutSettingSlopeUnit[key] = property[key];
			}
		},
		toggleTrenchLongitudinalSlopeUnit(state, unit) {
			state.trenchLongitudinalSlopeUnit = unit;
		},
		toggleFillSettingSlopeUnit(state, property) {
			for (const key of Object.keys(property)) {
				state.fillSettingSlopeUnit[key] = property[key];
			}
		},
		initObj(state) {
			state.objects = [];
		},
		updateObjInObjects(state, updatedObject) {
			const index = state.objects.findIndex(
				(obj) => obj.id === updatedObject.id,
			);
			if (index !== -1) {
				// オブジェクトを更新
				state.objects.splice(index, 1, updatedObject);
			}
		},
		setObj(state, objects) {
			state.objects = objects;
		},
		setObjectUpdating(state, value) {
			state.isObjectUpdating = value;
		},
		setVisiblePointCloudAndDesignList(state, pointCloudList) {
			state.visiblePointCloudAndDesignList = pointCloudList;
		},
		setActiveLineCrossSection(state, line) {
			state.activeLineCrossSection = line;
		},
		setActiveData(state, data) {
			state.activeData = data;
		},
		set_snackbar(state, snackbar) {
			snackbar.showing = true;
			snackbar.id = "notification_" + Date.now();
			state.snackbars.push(snackbar);
		},
		clear_snackbars(state) {
			state.snackbars = state.snackbars.filter((snackbar) => {
				return snackbar.showing == true;
			});
		},
		reset_snackbars(state) {
			state.snackbars = [];
		},
		setLoading(state, isLoading) {
			state.isLoading = isLoading;
		},
		set_initial_site_datas(state, ret) {
			state.initial_site_data = ret;
		},
		set_exclusion(state, flag) {
			state.isExclusion = flag;
		},
		set_conflict_coordinate_system(state, data) {
			state.conflictCoordinateSystem = data;
		},
	},
	actions: {
		getSiteInfo({ state, commit }, siteId) {
			return axios
				.get(`${import.meta.env.VITE_API_BASE}/sites/${siteId}`, {
					auth: state.authObject,
				})
				.then((response) => {
					console.log(response);
					commit("setSite", response.data);
					if (
						response?.data?.obj_connection_json &&
						response.data.obj_connection_json !== ""
					) {
						window["viewer"].loadConnectionsInSiteJSON(
							response.data.obj_connection_json,
						);
					}
				})
				.catch((err) => {
					console.error("GET SITE INFO", err);
					throw err;
				});
		},
		addToVisiblePointCloudAndDesignList({ state, commit, dispatch }, data) {
			let newArray = [];
			// 存在チェック
			const isExist = state.visiblePointCloudAndDesignList.find(
				(item) => item.getAssetId() === data.getAssetId(),
			);
			// 新規の場合配列の先頭に追加し、既に存在する場合は先頭に移動
			if (!isExist) {
				newArray = [data, ...state.visiblePointCloudAndDesignList];
			} else {
				const otherItems = state.visiblePointCloudAndDesignList.filter(
					(item) => item.getAssetId() !== data.getAssetId(),
				);
				newArray = [data, ...otherItems];
			}
			commit("setVisiblePointCloudAndDesignList", newArray);
			dispatch("addToActiveData", data);
		},
		removeFromVisiblePointCloudAndDesignList(
			{ state, commit, dispatch },
			data,
		) {
			const newArray = state.visiblePointCloudAndDesignList.filter(
				(item) => item.getAssetId() !== data.getAssetId(),
			);
			commit("setVisiblePointCloudAndDesignList", newArray);
			dispatch("removeFromActiveData", data);
		},
		addToActiveData({ state, commit }, data) {
			if (data instanceof PointCloud) {
				// 現在アクティブになっている点群はリストから除き、新しい点群を先頭に追加
				const arrayWithoutPointCloud = state.activeData.filter(
					(item) => !(item instanceof PointCloud),
				);
				commit("setActiveData", [data, ...arrayWithoutPointCloud]);
			} else if (data instanceof Design) {
				// 現在アクティブになっている設計データはリストから除き、新しい設計データを先頭に追加
				const arrayWithoutDesign = state.activeData.filter(
					(item) => !(item instanceof Design),
				);
				commit("setActiveData", [data, ...arrayWithoutDesign]);
			}
		},
		removeFromActiveData({ state, commit }, data) {
			const newArray = state.activeData.filter(
				(item) => item.getAssetId() !== data.getAssetId(),
			);
			commit("setActiveData", newArray);
		},
		setActiveLineCrossSection({ commit }, line) {
			commit("setActiveLineCrossSection", line);
		},
		setActiveLineCrossSectionById({ commit }, lineId) {
			const findLineEntityByLieId = (viewerLineId) => {
				const foundInArbitrary =
					store.state.arbitraryLine.arbitraryLineList.find(
						(item) => item.viewerLineId === viewerLineId,
					);

				if (foundInArbitrary) {
					return foundInArbitrary;
				}

				const foundInSima = store.state.simaLine.simaLineList.find(
					(item) => item.viewerLineId === viewerLineId,
				);
				return foundInSima;
			};
			const line = findLineEntityByLieId(lineId);
			commit("setActiveLineCrossSection", line);
		},
		changePanel(context, panelName) {
			context.commit("change_panel", panelName);
		},
		getUser() {
			return axios
				.get(`${import.meta.env.VITE_API_BASE}/user/me`, {
					auth: this.state.authObject,
				})
				.then((res) => {
					this.commit("setUser", res.data);
				});
		},
		updateInitialSiteInfo({ state, commit }, siteId) {
			axios
				.get(`${import.meta.env.VITE_API_BASE}/sites/${siteId}`, {
					auth: state.authObject,
				})
				.then((res) => {
					const updateInfo = res ? res.data : null;
					commit("set_initial_site_datas", updateInfo);
				});
		},
		deleteObject({ state, commit }, { siteId, obj }) {
			const params = {
				siteId: siteId,
				cid: obj.cid,
			};
			axios
				.delete(`${import.meta.env.VITE_API_BASE}/obj/`, {
					params,
					auth: state.authObject,
				})
				.then(() => {
					const objects = state.objects.filter((item) => item.id !== obj.id);
					commit("setObj", objects);
					console.log("delete success");
				});
		},
		async get_point_cloud_list({ state, commit }, site_id) {
			const pointCloudList = [];
			const res = await axios.get(
				`${import.meta.env.VITE_API_BASE}/pointcloud/${site_id}`,
				{
					auth: this.state.authObject,
				},
			);
			for (const pointCloud of res.data) {
				const currentPointCloud = state.point_cloud_list.find(
					(entity) => entity.getId() === pointCloud.point_cloud_id,
				);
				// すでにリストに存在する場合は更新。そうでない場合は新規追加
				if (currentPointCloud) {
					currentPointCloud.update(pointCloud);
					pointCloudList.push(currentPointCloud);
				} else {
					const newPointCloud = new PointCloud(
						pointCloud.point_cloud_id,
						pointCloud.point_cloud_name,
						pointCloud.asset_id,
						pointCloud.transparency,
						pointCloud.processing_status,
						pointCloud.processing_error,
						pointCloud.create_at,
						pointCloud.updated_at,
						pointCloud.selected_date,
						pointCloud.selected_time,
					);
					pointCloudList.push(newPointCloud);
				}
			}
			commit("set_point_cloud_list", pointCloudList);
		},
		// async get_point_cloud_data(_, point_cloud) {
		//     let response = await axios.get(
		//         `${import.meta.env.VITE_API_BASE}/pointcloud/data/${point_cloud.point_cloud_id}`,
		//         {
		//             auth: this.state.authObject,
		//         }
		//     );
		//     let offset = 0;
		//     try{
		//       const localizedPoint = await convertCoordinateToPointCloudCrs({x:response.data[0],y:response.data[1],z:response.data[2]});
		//       if(localizedPoint.z !== localizedPoint.z){
		//         throw "conversion error";
		//       }
		//       const cesiumAltitude = window["viewer"].getAltitude({x:response.data[0],y:response.data[1],z:response.data[2]});
		//       offset = cesiumAltitude - localizedPoint.z; //await calculateVerticalOffsetForPointCloud({x:response.data[0],y:response.data[1],z:response.data[2]});
		//     }
		//     catch(e){
		//       console.error(e);
		//     }
		//     return window["viewer"].addTileset(point_cloud.asset_id, "pointcloud", response.data, offset);
		// },
		async delete_point_cloud({ state, commit }, { point_cloud_id, asset_id }) {
			commit("set_point_cloud_loading", point_cloud_id);
			await axios.delete(`${import.meta.env.VITE_API_BASE}/pointcloud`, {
				data: {
					point_cloud_id,
				},
			});
			window["viewer"].removeTileset(Number.parseInt(asset_id), "pointcloud");
			localDb.deletePointCloud(state.user.id, state.site.site_uuid, asset_id);
			commit("delete_point_cloud", point_cloud_id);
		},
		async get_design_list({ state, commit }, site_id) {
			const designList = [];

			try {
				const designs = await axios.get(
					`${import.meta.env.VITE_API_BASE}/design/${site_id}`,
					{
						auth: this.state.authObject,
					},
				);
				for (const design of designs.data) {
					const currentDesign = state.design_list.find(
						(entity) => entity.getId() === design.design_id,
					);
					if (currentDesign) {
						currentDesign.update(design);
						designList.push(currentDesign);
					} else {
						const newDesign = new Design(
							"design",
							design.design_id,
							design.design_name,
							design.asset_id,
							design.color,
							design.transparency,
							design.processing_status,
							design.processing_error,
							design.create_at,
							design.selected_date,
							design.selected_time,
						);
						designList.push(newDesign);
					}
				}
			} catch (e) {
				// Todo display appropriate message
				console.warn(e);
			}

			try {
				const dxfLines = await axios.get(
					`${import.meta.env.VITE_API_BASE}/lineworks/all/${site_id}`,
					{
						auth: this.state.authObject,
					},
				);

				for (const dxfLine of dxfLines.data) {
					const currentLine = state.design_list.find(
						(entity) => entity.getId() === dxfLine.lineworks_id,
					);
					if (currentLine) {
						currentLine.update(dxfLine);
						designList.push(currentLine);
					} else {
						const newLine = new Line(
							"lineworks",
							dxfLine.lineworks_id,
							dxfLine.lineworks_name,
							dxfLine.lineworks_path,
							dxfLine.processing_status,
							dxfLine.create_at,
							dxfLine.site_id,
							"dxf",
						);
						designList.push(newLine);
					}
				}
			} catch (e) {
				// Todo display appropriate message
				console.warn(e);
			}

			designList.sort((a, b) => {
				const d1 = new Date(a.getCreatedAt());
				const d2 = new Date(b.getCreatedAt());
				if (d1 > d2) {
					return 1;
				} else if (d1 < d2) {
					return -1;
				} else {
					return 0;
				}
			});

			commit("set_design_list", designList);
		},
		// async get_design_data(_, design) {
		//     let response = await axios.get(
		//         `${import.meta.env.VITE_API_BASE}/design/data/${design.design_id}`,
		//         {
		//             auth: this.state.authObject,
		//         }
		//     );
		//     const localizedPoint = await convertCoordinateToPointCloudCrs({x:response.data.vertices[0],y:response.data.vertices[1],z:response.data.vertices[2]});
		//     const cesiumAltitude = window["viewer"].getAltitude({x:response.data.vertices[0],y:response.data.vertices[1],z:response.data.vertices[2]});
		//     const offset = cesiumAltitude - localizedPoint.z; //await calculateVerticalOffsetForPointCloud({x:response.data[0],y:response.data[1],z:response.data[2]});
		//     return window["viewer"].addTileset(design.asset_id, "design", response.data.vertices, offset, response.data.indices, { design: { mesh_color: hexToRgb(design.getMeshColor()) }, common: { transparency: design.getTransparency() } });
		// },
		// 設計データ削除
		async delete_design({ state, commit }, { design_id, asset_id }) {
			commit("set_design_loading", design_id);
			try {
				await axios.delete(`${import.meta.env.VITE_API_BASE}/design`, {
					data: { design_id },
				});
				window["viewer"].removeTileset(Number.parseInt(asset_id), "design");
				localDb.deleteDesign(state.user.id, state.site.site_uuid, asset_id);

				commit("delete_design", design_id);
				return { success: true, message: "Design deleted successfully." };
			} catch (error) {
				console.error("Error deleting design:", error);
				return { success: false, message: "Error deleting design." };
			}
		},
		// 線形データ削除
		async delete_lineworks({ state, commit }, { _id, _lineType }) {
			const lineworksId = _id;
			commit("set_lineworks_loading", lineworksId);
			try {
				if (_lineType === "dxf") {
					await axios.delete(
						`${import.meta.env.VITE_API_BASE}/lineworks/${lineworksId}`,
					);
				} else if (_lineType === "sima") {
					await axios.delete(
						`${import.meta.env.VITE_API_BASE}/linearfile/${state.site.site_uuid}/${lineworksId}`,
					);
				}

				commit("delete_lineworks", lineworksId);
				return { success: true, message: "Linework deleted successfully." };
			} catch (error) {
				console.error("Error deleting design:", error);
				return { success: false, message: "Error deleting linework." };
			}
		},
		async get_obj({ state, getters, commit }, json_id) {
			const objects = state.objects;
			const response = await axios.get(
				`${import.meta.env.VITE_API_BASE}/obj/${json_id}`,
				{
					auth: state.authObject,
				},
			);
			const data = response.data;
			const currentObj = objects.find(
				(entity) => entity.cid === data.props.cid,
			);

			if (currentObj) {
				// リストに存在する場合は更新
				if (data.props.type === constants.objectType.ROAD) {
					// 仮設道路オブジェクトの処理
					objectFunction.copyRoadProps(currentObj, data.props);
					currentObj.json =
						typeof data.json === "object"
							? JSON.stringify(data.json)
							: data.json;
				} else if (data.props.type === constants.objectType.FLAT) {
					// 平場オブジェクトの処理
					objectFunction.copyFlatProps(currentObj, data.props);
					currentObj.json =
						typeof data.json === "object"
							? JSON.stringify(data.json)
							: data.json;
				} else if (data.props.type === constants.objectType.TRENCH) {
					objectFunction.copyTrenchProps(currentObj, data.props);
					currentObj.json =
						typeof data.json === "object"
							? JSON.stringify(data.json)
							: data.json;
				}
			} else {
				// リストに存在しない場合は新規追加
				let obj = null;
				if (data.props.type === constants.objectType.ROAD) {
					// 仮設道路オブジェクトの処理
					obj = JSON.parse(JSON.stringify(getters.defaultRoadProperty));
					objectFunction.copyRoadProps(obj, data.props);
					obj.json =
						typeof data.json === "object"
							? JSON.stringify(data.json)
							: data.json;
				} else if (data.props.type === constants.objectType.FLAT) {
					// 平場オブジェクトの処理
					obj = JSON.parse(JSON.stringify(getters.defaultFlatGroundProperty));
					objectFunction.copyFlatProps(obj, data.props);
					obj.json =
						typeof data.json === "object"
							? JSON.stringify(data.json)
							: data.json;
				} else if (data.props.type === constants.objectType.TRENCH) {
					obj = JSON.parse(JSON.stringify(getters.defaultTrenchProperty));
					objectFunction.copyTrenchProps(obj, data.props);
					obj.json =
						typeof data.json === "object"
							? JSON.stringify(data.json)
							: data.json;
				}
				objects.push(obj);
			}
			commit("setObj", objects);
		},
		async get_obj_list({ state, getters, commit }, site_id) {
			const objects = [];
			const response = await axios.get(
				`${import.meta.env.VITE_API_BASE}/obj/?siteId=${site_id}`,
				{
					auth: state.authObject,
				},
			);
			for (const data of response.data) {
				const currentObj = state.objects.find(
					(entity) => entity.cid === data.props.cid,
				);
				if (currentObj) {
					// リストに存在する場合は更新
					if (data.props.type === constants.objectType.ROAD) {
						// 仮設道路オブジェクトの処理
						objectFunction.copyRoadProps(currentObj, data.props);
						currentObj.json =
							typeof data.json === "object"
								? JSON.stringify(data.json)
								: data.json;
					} else if (data.props.type === constants.objectType.FLAT) {
						// 平場オブジェクトの処理
						objectFunction.copyFlatProps(currentObj, data.props);
						currentObj.json =
							typeof data.json === "object"
								? JSON.stringify(data.json)
								: data.json;
					} else if (data.props.type === constants.objectType.TRENCH) {
						objectFunction.copyTrenchProps(currentObj, data.props);
						currentObj.json =
							typeof data.json === "object"
								? JSON.stringify(data.json)
								: data.json;
					}
					objects.push(currentObj);
				} else {
					// リストに存在しない場合は新規追加
					let obj = null;
					if (data.props.type === constants.objectType.ROAD) {
						// 仮設道路オブジェクトの処理
						obj = JSON.parse(JSON.stringify(getters.defaultRoadProperty));
						objectFunction.copyRoadProps(obj, data.props);
						obj.json =
							typeof data.json === "object"
								? JSON.stringify(data.json)
								: data.json;
						// objectFunction.prepareTempRoadSlopesForRndering(obj);
					} else if (data.props.type === constants.objectType.FLAT) {
						// 平場オブジェクトの処理
						obj = JSON.parse(JSON.stringify(getters.defaultFlatGroundProperty));
						objectFunction.copyFlatProps(obj, data.props);
						obj.json =
							typeof data.json === "object"
								? JSON.stringify(data.json)
								: data.json;
						// objectFunction.prepareTempFlatSlopesForRndering(obj);
					} else if (data.props.type === constants.objectType.TRENCH) {
						obj = JSON.parse(JSON.stringify(getters.defaultTrenchProperty));
						objectFunction.copyTrenchProps(obj, data.props);
						obj.json =
							typeof data.json === "object"
								? JSON.stringify(data.json)
								: data.json;
					}
					objects.push(obj);
				}
			}
			commit("setObj", objects);
		},
		async updateObj(_data, { json, props, objConnectionJSON }) {
			try {
				await axios.put(
					`${import.meta.env.VITE_API_BASE}/obj`,
					{
						siteId: this.state.site.site_uuid,
						objJSON: json,
						objProps: props,
						objConnectionJSON: objConnectionJSON,
					},
					{
						auth: this.state.authObject,
					},
				);
			} catch (e) {
				console.error(e);
				throw e;
			}
		},
		startObjectUpdating({ commit }) {
			commit("setObjectUpdating", true);
		},
		endObjectUpdating({ commit }) {
			commit("setObjectUpdating", false);
		},
		toggleRoadParameterSlopeUnit({ commit }, property) {
			commit("toggleRoadParameterSlopeUnit", property);
		},
		toggleTrenchParameterSlopeUnit({ commit }, property) {
			commit("toggleTrenchParameterSlopeUnit", property);
		},
		toggleCutSettingSlopeUnit({ commit }, property) {
			commit("toggleCutSettingSlopeUnit", property);
		},
		toggleTrenchCutSettingSlopeUnit({ commit }, property) {
			commit("toggleTrenchCutSettingSlopeUnit", property);
		},
		toggleFillSettingSlopeUnit({ commit }, property) {
			commit("toggleFillSettingSlopeUnit", property);
		},
		toggleTrenchLongitudinalSlopeUnit({ commit }, property) {
			commit("toggleTrenchLongitudinalSlopeUnit", property);
		},
		updateIsLoading({ commit }, isLoading) {
			commit("setLoading", isLoading);
		},
		/**
		 * タスク実行前にスピナーを表示し、完了後に非表示にする
		 * @param {*} asyncTask
		 */
		async executeWithSpinner({ commit }, asyncTask) {
			return new Promise((resolve, reject) => {
				// ローディング開始
				commit("setLoading", true);
				// スピナー表示までラグがあるため処理開始を遅延させる
				setTimeout(async () => {
					try {
						await asyncTask();
					} catch (e) {
						reject(e);
					} finally {
						// ローディング終了
						commit("setLoading", false);
						resolve();
					}
				}, 100);
			});
		},
		startCreationMode({ commit }, objectType) {
			const updateValue = {
				name: "inCreationMode",
				value: {
					isActive: true,
					objectType,
				},
			};
			commit("set_modes", updateValue);
		},
		endCreationMode({ commit }) {
			const updateValue = {
				name: "inCreationMode",
				value: {
					isActive: false,
					objectType: null,
				},
			};
			commit("set_modes", updateValue);
		},
		startEditMode({ commit }, objectId) {
			const updateValue = {
				name: "inEditMode",
				value: {
					isActive: true,
					editTargetId: objectId,
				},
			};
			commit("set_modes", updateValue);
		},
		endEditMode({ commit }) {
			const updateValue = {
				name: "inEditMode",
				value: {
					isActive: false,
					editTargetId: null,
				},
			};
			commit("set_modes", updateValue);
		},
		start2dImageEdit({ commit }, imageId) {
			const updateValue = {
				name: "inEditDrawing2dImage",
				value: {
					isActive: true,
					editTargetId: imageId,
				},
			};
			commit("set_modes", updateValue);
		},
		end2dImageEdit({ commit }) {
			const updateValue = {
				name: "inEditDrawing2dImage",
				value: {
					isActive: false,
					editTargetId: null,
				},
			};
			commit("set_modes", updateValue);
		},
	},
	getters: {
		lang: (state) => state.lang,
		pointCloudAndDesignList(state) {
			return [
				...state.point_cloud_list,
				...state.design_list,
				...state.drawing2dImage.drawing2dImageList,
			];
		},
		defaultRoadProperty() {
			return {
				//Todo extract it as services
				id: null,
				cid: null, // cash id *first assigned id
				type: null,
				name: null,
				isShow: false,
				isOpen: false,
				edit: false,
				panel: {
					road: false,
					cut: false,
					fill: false,
					volume: false,
				},
				cutVolume: 0,
				fillVolume: 0,
				transparency: 1,
				info: {
					length: 0,
					height: null,
					slope: null,
				},
				settings: {
					commonSettings: {
						leftWidth: 2.5,
						rightWidth: 2.5,
						leftSlope: 50,
						rightSlope: 50,
						isSuperElevation: false,
						interpolationPitch: 5,
						surfaceColor: "#DDDDDD",
						segmentSettings: [],
					},
					cutSettings: {
						roadHeight: 5,
						moundSlope: 1,
						shelfWidth: 1.5,
						shelfSlope: 100,
						bLeftRightSettings: false,
						generateShelf: true,
						leftSlopeHeight: 5,
						leftMoundSlope: 1,
						rightSlopeHeight: 5,
						rightMoundSlope: 1,
						leftShelfWidth: 1.5,
						leftShelfSlope: 100,
						rightShelfWidth: 1.5,
						rightShelfSlope: 100,
						color: "#FFA500",
						outline: true,
					},
					embarkmentSettings: {
						roadHeight: 5,
						moundSlope: 1.5,
						shelfWidth: 1.5,
						shelfSlope: 100,
						bLeftRightSettings: false,
						generateShelf: true,
						leftSlopeHeight: 5,
						leftMoundSlope: 1.5,
						rightSlopeHeight: 5,
						rightMoundSlope: 1.5,
						leftShelfWidth: 1.5,
						leftShelfSlope: 100,
						rightShelfWidth: 1.5,
						rightShelfSlope: 100,
						color: "#008000",
						outline: true,
					},
				},
				cash: {
					leftShelfWidth: 2,
					rightShelfWidth: 2,
				},
			};
		},
		defaultTrenchProperty() {
			return {
				//Todo extract it as services
				id: null,
				cid: null, // cash id *first assigned id
				type: null,
				name: null,
				isShow: false,
				isOpen: false,
				edit: false,
				panel: {
					trench: false,
					cut: false,
					volume: false,
				},
				cutVolume: 0,
				transparency: 1,
				info: {
					length: 0,
					height: null,
					slope: null,
				},
				settings: {
					commonSettings: {
						width: 1.5,
						leftSlope: 50,
						rightSlope: 50,
						isSuperElevation: false,
						interpolationPitch: 5,
						surfaceColor: "#964B00",
						segmentSettings: [],
						specifiedElevation: null,
						longitudinalSlope: {
							slopeType: LongitudinalSlopeType.DEFAULT,
							slopeValue: 0,
						},
						destination: {
							target: RubbingDestination.POINT_CLOUD,
							id: 0,
							specifiedAltitude: null,
						},
					},
					cutSettings: {
						trenchHeight: 5,
						moundSlope: 0.1,
						shelfWidth: 1.5,
						shelfSlope: 100,
						bLeftRightSettings: false,
						generateShelf: false,
						leftSlopeHeight: 5,
						leftMoundSlope: 0.1,
						rightSlopeHeight: 5,
						rightMoundSlope: 0.1,
						leftShelfWidth: 1.5,
						leftShelfSlope: 100,
						rightShelfWidth: 1.5,
						rightShelfSlope: 100,
						color: "#FFA500",
						outline: true,
					},
				},
				cash: {
					leftShelfWidth: 2,
					rightShelfWidth: 2,
				},
			};
		},
		defaultFlatGroundProperty() {
			return {
				id: null,
				cid: null, // cash id *first assigned id
				type: null,
				name: null,
				isShow: false,
				isOpen: false,
				edit: false,
				panel: {
					shelf: false,
					volume: false,
					cutFlat: false,
					flatGround: false,
				},
				cutVolume: 0,
				fillVolume: 0,
				surfaceArea: 0,
				transparency: 1,
				info: {
					maxHeight: 0,
				},
				settings: {
					commonSettings: {
						interpolationPitch: 5,
						withSlope: true,
					},
					cutSettings: {
						height: 7,
						moundSlope: 1,
						shelfWidth: 1.5,
						shelfSlope: 100,
						generateShelf: true,
						color: "#FFA500",
					},
					embankmentSettings: {
						height: 5,
						moundSlope: 1.5,
						shelfWidth: 1.5,
						shelfSlope: 100,
						generateShelf: true,
						color: "#008000",
					},
				},
			};
		},
		/**
		 * 現場の点群リストを返却する
		 * @param {*} state
		 * @returns PointCloud 表示中の点群リスト
		 */
		sitePointClouds: (state) => state.point_cloud_list,
		/**
		 * 表示中の点群リストを返却する
		 * @param {*} state
		 * @returns PointCloud 表示中の点群リスト
		 */
		visiblePointClouds: (state) =>
			state.point_cloud_list.filter((pointCloud) => pointCloud.getVisibility()),
		visiblePointCloudList: (state) =>
			state.visiblePointCloudAndDesignList.filter(
				(item) => item instanceof PointCloud,
			),
		activePointCloud: (state) => {
			let activePointCloud = state.activeData.find(
				(item) => item instanceof PointCloud,
			);
			if (!activePointCloud) {
				const pointClouds = state.point_cloud_list.filter((pointCloud) =>
					pointCloud.getVisibility(),
				);
				if (pointClouds && pointClouds.length > 0) {
					// store.dispatch("addToVisiblePointCloudAndDesignList", pointClouds[0]);
					activePointCloud = pointClouds[0];
				}
			}
			return activePointCloud || null;
		},
		activeDesign: (state) =>
			state.activeData.find((item) => item instanceof Design) || null,
		objects: (state) => state.objects,
		activePointCloudId: (state) => {
			const pointClouds = state.point_cloud_list.filter((pointCloud) =>
				pointCloud.getVisibility(),
			);
			const id = pointClouds[0]
				? Number.parseInt(pointClouds[0].asset_id)
				: undefined;
			return id;
		},
		viewer: (state) => state.viewer,
		isLoading: (state) => state.isLoading,
		pointCloudCount: (state) => state.point_cloud_list.length,
		designDataCount: (state) => state.design_list.length,
		segmentsByRoad: (state) => (roadId) => {
			const roadSettings = state.objects.filter((road) => road.id === roadId);
			// const _segments = []
			// // console.log("roadSettings", roadSettings);
			// const roadJSON = JSON.parse(roadSettings[0].json);
			// // console.log("json", roadJSON);
			// if (roadJSON.roadSettings.commonSettings.segmentSettings) {
			//     Array.from(roadJSON.roadSettings.commonSettings.segmentSettings).forEach(seg => _segments.push(
			//         {
			//             slopeType: seg.slopeType,
			//             startIndex: seg.startIndex,
			//             endIndex: seg.endIndex,
			//             slope: seg.slope
			//         }
			//     ));
			// }
			// return _segments;
			return roadSettings[0].settings.commonSettings.segmentSettings;
		},
		getRoads: (state) => {
			return state.objects.filter((o) => o.type === 0);
		},
		site: (state) => state.site,
		linearList: (state, getters, rootState) => {
			const linearList = [
				...rootState.arbitraryLine.arbitraryLineList,
				...rootState.simaLine.simaLineList,
			];
			// createdAtで昇順にソート
			linearList.sort((a, b) => {
				const d1 = new Date(a.createdAt);
				const d2 = new Date(b.createdAt);
				if (d1 > d2) {
					return 1;
				} else if (d1 < d2) {
					return -1;
				} else {
					return 0;
				}
			});
			return linearList;
		},
		is_crs_conversion_in_progress: (state) =>
			state.is_crs_conversion_in_progress,
		conflictCoordinateSystem: (state) => state.conflictCoordinateSystem,
	},
	modules: {
		dialogs,
		addRoadTerrainByAi,
		measurement,
		arbitraryLine,
		simaLine,
		lineWorksCrossSection,
		dxf,
		lock,
		drawing2dImage,
	},
});

export default store;
